exports.components = {
  "component---src-pages-2048-privacy-tsx": () => import("./../../../src/pages/2048-privacy.tsx" /* webpackChunkName: "component---src-pages-2048-privacy-tsx" */),
  "component---src-pages-2048-tsx": () => import("./../../../src/pages/2048.tsx" /* webpackChunkName: "component---src-pages-2048-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-awake-rps-tsx": () => import("./../../../src/pages/awake-rps.tsx" /* webpackChunkName: "component---src-pages-awake-rps-tsx" */),
  "component---src-pages-botter-tsx": () => import("./../../../src/pages/botter.tsx" /* webpackChunkName: "component---src-pages-botter-tsx" */),
  "component---src-pages-cards-tsx": () => import("./../../../src/pages/cards.tsx" /* webpackChunkName: "component---src-pages-cards-tsx" */),
  "component---src-pages-codenames-index-tsx": () => import("./../../../src/pages/codenames/index.tsx" /* webpackChunkName: "component---src-pages-codenames-index-tsx" */),
  "component---src-pages-codenames-master-index-tsx": () => import("./../../../src/pages/codenames/master/index.tsx" /* webpackChunkName: "component---src-pages-codenames-master-index-tsx" */),
  "component---src-pages-decide-tsx": () => import("./../../../src/pages/decide.tsx" /* webpackChunkName: "component---src-pages-decide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kol-tsx": () => import("./../../../src/pages/kol.tsx" /* webpackChunkName: "component---src-pages-kol-tsx" */),
  "component---src-pages-monitor-tsx": () => import("./../../../src/pages/monitor.tsx" /* webpackChunkName: "component---src-pages-monitor-tsx" */),
  "component---src-pages-node-calc-tsx": () => import("./../../../src/pages/node-calc.tsx" /* webpackChunkName: "component---src-pages-node-calc-tsx" */),
  "component---src-pages-notion-sort-tsx": () => import("./../../../src/pages/notion-sort.tsx" /* webpackChunkName: "component---src-pages-notion-sort-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */)
}

